@import 'src/assets/styles/variables.scss';
.left-blocks-holder {
  padding: var(--gap) 0;
  @media screen and (min-height: 530px) {
    padding: rem(20) 0;
    margin: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  h1 {
    font: 700 rem(22) / rem(30) $f;
    margin: 0 0 rem(16);
    @include mobile {
      font: 700 rem(22) / rem(30) $f;
      margin: 0 0 rem(16);
    }
  }
  h2 {
    font: 300 rem(16) / rem(22) $f;
    margin: 0 0 rem(20);
  }
  hr {
    width: 100%;
    border-top: rem(1) solid sc(b, 0.1);
    margin: 0 0 rem(20);
    @include mobile {
      margin-top: rem(8);
    }
  }
}
.our-brands-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: rem(30);
  @include mobile {
    font-size: rem(22);
  }
  > * {
    width: calc((100% - #{rem(60)}) / 3);
    margin: 0 0 rem(30);
    &:nth-child(2) {
      margin: 0 rem(30) rem(30);
    }
    @include mobile {
      width: calc((100% - #{rem(38)}) / 2);
      margin: 0 0 rem(22);
      &:nth-child(1), &:nth-child(3) {
        margin: 0 rem(38 / 2) rem(22) 0;
      }
      &:nth-child(2), &:nth-child(4) {
        margin: 0 0 rem(22) rem(38 / 2);
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    height: rem(30);
    @include mobile {
      height: rem(22);
    }
    > li {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &:first-child {
        border-radius: rem(6);
        color: white;
        &.icon-spring-builder-shape {
          color: c(b);
        }
      }
      &:last-child {
        flex: auto;
        margin: 0 0 0 rem(14);
        @include mobile {
          margin: 0 0 0 rem(10);
        }
      }
    }
  }
}
