@import 'src/assets/styles/variables.scss';
.news-section-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.news-head {
  width: 100%;
  display: flex;
  align-items: baseline;
  font: 700 rem(22) / rem(30) $f;
  margin: 0 0 rem(22);
  @include mobile {
    font-size: rem(16);
    margin: 0 0 rem(16);
  }
  > li {
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: baseline;
    &:first-child {
      flex: auto;
    }
  }
  a {
    font: rem(16) / 1 $f;
    color: sc(b, 0.8);
    @include hover {
      color: sc(b);
    }
    @include mobile {
      font-size: rem(16);
    }
  }
}
.new-items-holder {
  width: 100%;
  overflow: hidden;
  @include mobile {
    width: calc(100% + #{rem(40)});
    margin: 0 -rem(20) rem(30);
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  > ul {
    min-width: 100%;
    display: flex;
    @include mobile {
      padding: 0 rem(20) rem(15);
      &:after {
        content: '';
        display: block;
        width: rem(1);
        height: rem(1);
        flex-shrink: 0;
        margin: 0 0 0 rem(10);
      }
    }
    > li {
      width: calc((100% - #{rem(14 * 2)}) / 3);
      flex-shrink: 0;
      @include mobile {
        width: rem(168);
        margin: 0 rem(10) 0 0;
      }
      + li {
        @include desktop {
          margin: 0 0 0 rem(14);
        }
      }
    }
  }
}
.news-story {
  width: 100%;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  figure {
    padding: per(138, 220) 0 0;
    border-radius: rem(6);
    background: no-repeat 50% 50% / cover;
    transition: 300ms filter;
    &.skeleton {
      background: linear-gradient(135deg, sc(b, 0.07) 0%, sc(b, 0.05) 50%, sc(b, 0.07) 100%) 0 0 / 200%;
      animation: sp-skeleton 0.3s infinite linear;
    }
  }
  .n-title-holder {
    @at-root {
      a#{&} {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        animation: 1s text-reveal forwards;
        @keyframes text-reveal {
          to {
            opacity: 1;
            max-height: rem(100);
          }
        }
      }
    }
  }
  p {
    width: 100%;
    font: rem(14) / rem(20) $f;
    color: sc(b, 0.9);
    padding: rem(8) rem(5) 0;
    @include mobile {
      font: rem(12) / rem(18) $f;
    }
  }
  @include hover {
    figure {
      filter: brightness(1.2);
    }
    p {
      color: sc(b);
    }
  }
}
@keyframes sp-skeleton {
  100% {
    background-position: -200%;
  }
}
