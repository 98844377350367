@import "variables";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

:root {
  --font-family: 'Open Sans', 'Arial', sans-serif;
  --gap: #{rem(80)};
  @each $name, $color in $c {
    --#{$name}: #{$color};
  }
  @media (prefers-color-scheme: dark) {
    @include over(b, f);
  }
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  border-radius: 0;
  background: none;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;

  &, &:before, &:after {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
  transition: 400ms color, 400ms background;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-ms-clear {
  display: none;
}

img {
  display: block;
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
  appearance: none;
  display: block;
  color: inherit;

  &::-webkit-input-placeholder {
    opacity: 1;
    font-family: inherit;
  }

  &:-moz-placeholder {
    opacity: 1;
    font-family: inherit;
  }

  &::-moz-placeholder {
    opacity: 1;
    font-family: inherit;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    font-family: inherit;
  }
}

select {
  optgroup, option {
    color: sc(b);
    background: c(b);
  }
}

html {
  font: 10px / 1.15 $f;
  -webkit-text-size-adjust: 100%;
  color: sc(b);
  min-height: 100%;
  display: flex;
  user-select: none;
  background: #f4f6f8;
  @media (prefers-color-scheme: dark) {
    background: #0b1017;
  }
  &, body, .root-container {
    width: 100%;
    min-height: 100%;
  }
  @include desktop-br(1366px) {
    font-size: 9px;
  }
  @include desktop-br(1076px) {
    font-size: 9px;
  }
  @include desktop-br(968px) {
    font-size: 8px;
  }
  @include desktop-br(860px) {
    font-size: 7px;
  }
  @include desktop-br(753px) {
    font-size: 6px;
  }
  @include mobile {
    font-size: 10px;
  }
  @media screen and (max-width: 374px) {
    font-size: 7px;
  }
}

body {
  font-size: rem(14);
  display: flex;
}

li {
  display: block;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button {
    background-color: sc(b, 0.6);
  }
}

.root-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  header, footer {
    flex-shrink: 0;
    width: 100%;
  }
  section, > *:only-child {
    flex: auto;
    width: 100%;
  }
}
.loader-fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(40);
  color: sc(b, 0.3);
}
@media (prefers-color-scheme: dark) {
  img[src*='bet-construct.svg'],
  img[src*='scout-data.svg'] {
    filter: saturate(0) invert(1) brightness(100);
  }
}

::selection {
  background: c(hero, 0.1);
  color: c(hero);
  -webkit-text-fill-color: c(hero);
}
.color-confirm {
  color: c(confirm);
  @include hover {
    color: ch(confirm);
  }
}
.color-danger {
  color: c(danger);
  @include hover {
    color: ch(danger);
  }
}
