@import "reset";
section {
    display: flex;
}
.guide {
    margin: auto;
    padding: 0 rem(20);
    max-width: rem(1036 + 40);
    width: 100%;
    @include mobile {
        padding: 0 rem(12);
    }
}
.box-holder {
    width: 100%;
    border-radius: rem(8);
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
    padding: rem(36) rem(45) 0;
    background: c(b);
    box-shadow: 0 rem(3) rem(10) -rem(6) rgba(0, 0, 0, 0.3);
    &.wide {
        padding: rem(42) rem(40);
    }
    @include mobile {
        padding: rem(28) rem(20) 0;
        box-shadow: 0 rem(6) rem(12) rgba(black, 0.08);
        &:not(.wide) {
            $s: 8;
            margin-left: -#{rem($s)};
            width: calc(100% + #{rem($s*2)});
        }
        &.wide {
            padding: rem(28) rem(24);
        }
    }
}
.box-head {
    margin: 0 0 rem(40);
    width: 100%;
    h2 {
        font: $f-600 rem(30) / rem(32) $f;
        text-transform: capitalize;
    }
    h3 {
        margin: rem(10) 0 0;
        font: rem(16) / rem(22) $f;
        opacity: 0.7;
    }
    .wide & {
        margin: 0 0 rem(24);
        h2 {
            font: $f-600 rem(24) / rem(30) $f;
        }
    }
    &.c-danger {
        h2 {
            color: c(danger);
        }
    }
}
.box-footer {
    padding: rem(24) 0;
    font: rem(14) / rem(24) $f;
    color: sc(b, 0.6);
}
.box-footer-action {
    width: calc(100% + #{rem(40+40)});
    margin: rem(42) 0 -#{rem(42)} -#{rem(40)};
    align-self: flex-end;
    flex-shrink: 0;
    padding: 0 rem(45);
    height: rem(48);
    background: sc(b, 0.03);
    color: c(hero);
    font: 500 rem(16) / rem(18) $f;
    cursor: pointer;
    border-radius: 0 0 rem(4) rem(4);
    transition: 250ms color, 250ms box-shadow, 250ms opacity, 250ms filter;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: inset 0 0 0 c(hero);
    @include mobile {
        width: calc(100% + #{rem(24+24)});
        margin: rem(24) 0 -#{rem(28)} -#{rem(24)};
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.5;
        filter: saturate(0);
    }
    @include hover {
        color: white;
        box-shadow: inset 0 rem(48) 0 c(hero);
    }
    &.t-success {
        @include over(hero, confirm);
        flex-direction: row-reverse;
        justify-content: center;
    }
    i {
        display: block;
        margin: 0 rem(6) 0 0;
    }
}
.right-arrow-animation {
    .icon-long-arrow-right {
        font-size: rem(22);
        margin: 0 0 0 rem(8);
        transition: 400ms transform;
    }
    @include hover {
        .icon-long-arrow-right {
            transform: translateX(rem(6));
        }
    }
}
.button {
    width: 100%;
    min-width: rem(190);
    height: rem(42);
    border-radius: rem(42);
    background: c(hero);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font: $f-600 rem(14) / rem(16) $f;
    padding: 0 rem(24);
    cursor: pointer;
    transition: 500ms color, 500ms background, 500ms opacity;
    position: relative;
    @include hover {
        background: ch(hero);
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &.loading {
        color: transparent !important;
        pointer-events: none;
        .icon-spinner {
            $s: 22;
            font-size: rem($s);
            position: absolute;
            color: white;
            top: calc(50% - #{rem($s/2)});
            left: calc(50% - #{rem($s/2)});
        }
    }
    &.t-success, &.c-confirm {
        @include over(hero, confirm)
    }
    &.t-blue {
        @include over(hero, blue)
    }
    &.cr-half {
        border-radius: rem(4);
    }
    &.t-small {
        min-width: inherit;
        padding: 0 rem(14);
        height: rem(30);
        font-size: rem(12);
        i {
            margin: 0 rem(6) 0 0;
        }
    }
    &.t-medium {
        padding: 0 rem(24);
        height: rem(36);
        font-size: rem(14);
        min-width: inherit;
    }
}
.sso-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    > * {
        width: 100%;
        + * {
            margin: rem(30) 0 0;
        }
    }
}
.checkbox-group-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: rem(6) 0 0;
    button {
        font: 500 rem(14) / rem(16) $f;
        transition: 300ms color;
        cursor: pointer;
    }
}
.label-holder {
    display: block;
    text-align: left;
    &:not(.inline) {
        width: 100%;
    }
    .label-title {
        margin: 0 0 rem(8);
        color: sc(b, 0.5);
        font: 500 rem(12) / rem(14) $f;
        &:first-letter {
            text-transform: capitalize;
        }
    }
}
.input-holder {
    position: relative;
    input:not([type="checkbox"]), select {
        display: block;
        width: 100%;
        height: rem(48);
        padding: 0 rem(20) 0 rem(46);
        border-radius: rem(2);
        border: rem(1) solid sc(b, 0.1);
        background: sc(b, 0.015);
        transition: 500ms border-color;
        color: sc(b, 0.8);
        caret-color: sc(b, 0.8);
        -webkit-text-fill-color: sc(b, 0.8);
        &[readonly] {
            cursor: default;
            border-style: dashed;
        }
        &:not([readonly]) {
            &:not(select):focus {
                border-color: sc(b, 0.4);
            }
            @include hover {
                border-color: sc(b, 0.4);
            }
        }
        &:-webkit-autofill {
            -webkit-text-fill-color: sc(b, 0.8) !important;
            -webkit-background-clip: text !important;
        }
    }
    .error & {
        input:not([type="checkbox"]) {
            background: c(danger, 0.05) !important;
            border-color: c(danger) !important;
            &:-webkit-autofill {
                -webkit-text-fill-color: sc(b, 0.8) !important;
                -webkit-background-clip: text !important;
            }
        }
    }
    &.t-checkbox {
        display: flex;
        cursor: pointer;
        font: rem(14) / rem(24) $f;
        .reversed & {
            flex-direction: row-reverse;
        }
        p {
            flex: auto;
            margin: 0 rem(12) 0 0;
            .reversed & {
                margin: 0 0 0 rem(12);
            }
        }
        small {
            flex-shrink: 0;
            width: rem(18);
            height: rem(18);
            margin: rem(3);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: rem(4);
            border: rem(1) solid sc(b, 0.5);
            color: white;
            transition: 400ms box-shadow, 400ms border-color;
            box-shadow: inset 0 0 0  c(confirm);
            i {
                transition: transform 400ms;
                transform: scale(0);
                font-size: rem(16);
            }
        }
        @include hover {
            small {
                border-color: sc(b, 0.7);
            }
        }
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            pointer-events: none;
            opacity: 0;
            &:checked ~ small {
                box-shadow: inset 0 0 0 rem(14) c(confirm);
                border-color: c(confirm);
                i {
                    transform: scale(1);
                }
            }
            &:not(:checked) ~ small {
                .error & {
                    border-color: c(danger);
                }
            }
        }
    }
    a {
        color: c(link);
        font-weight: $f-600;
        @include hover {
            color: ch(link);
        }
    }
    .c-blue & {
        @include over(confirm, blue);
    }
}
.input-cols {
    width: 100%;
    display: flex;
    align-items: flex-start;
    > * {
        width: 100%;
        + * {
            margin: 0 0 0 rem(26);
            @include mobile {
                margin: 0 0 0 rem(10);
            }
        }
    }
}
.account-actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 500;
    > ul {
        display: flex;
        width: 100%;
        align-items: center;
        padding: rem(18) 0;
        margin: 0 0 rem(20);
        border: rem(1) solid sc(b, 0.1);
        border-width: rem(1) 0;
        > li {
            &:first-child {
                flex: auto;
            }
            &:last-child {
                flex-shrink: 0;
            }
        }
    }
}
.inline-button {
    color: c(danger);
    transition: 400ms color;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
        margin: 0 rem(8) 0 0;
    }
    @include hover {
        color: ch(danger);
    }
}
.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem(20);
    display: flex;
    z-index: 900;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: rgba(black, 0.3);
    opacity: 0;
    animation: 300ms popup-backdrop forwards;
    backdrop-filter: blur(rem(6));
    @include mobile {
        padding: rem(12);
    }
    @keyframes popup-backdrop {
        to {
            opacity: 1;
        }
    }
    .popup-content {
        margin: auto;
        max-width: rem(504);
        width: 100%;
        opacity: 0;
        transform: scale(0.5);
        animation: 300ms popup-content 150ms forwards;
        @keyframes popup-content {
            to {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    .popup-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        > * {
            width: auto;
            font: 500 rem(16) / rem(18) $f;
            + * {
                margin: 0 0 0 rem(26);
            }
        }
    }
    .inline-action {
        opacity: 0.6;
        cursor: pointer;
        transition: 400ms opacity;
        @include hover {
            opacity: 0.9;
        }
    }
}
.icon-spring-bme-shape, .icon-p-id-1 {
    background: #3d4ebc;
    color: white;
}
.icon-feed-construct-shape, .icon-p-id-4 {
    background: #a61f67;
    color: white;
}
.icon-feed-construct-text {
    color: #a61f67;
}
.icon-spring-shape, .icon-p-id-3 {
    background: #00afad;
    color: white;
}
.icon-spring-builder-shape, .icon-p-id-2, .icon-p-id-5 {
    background: sc(b);
}
.bet-construct-products-holder {
    .drop-content-holder {
        max-width: inherit !important;
    }
}
.icon-spinner {
  &:before {
    display: block;
    animation: spin 300ms linear infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
