@import "../../assets/styles/variables";
.layout-holder {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  > li {
    display: flex;
    @include mobile {
      order: 1;
      padding: 0 rem(20);
      width: 100%;
    }
    &.l-left {
      @include desktop {
        width: per(930);
      }
    }
    &.l-right {
      background: c(b);
      position: relative;
      width: 100%;
      &:not(:only-child) {
        @include desktop {
          width: per(510);
        }
        @include mobile {
          order: 0;
        }
      }
      &:only-child {
        .l-r-content-holder {
          width: 100%;
          max-width: rem(380);
          padding-bottom: rem(100);
        }
        .c-head {
          text-align: center;
        }
      }
    }
  }
}
.l-l-content-holder {
  @include desktop {
    @media screen and (min-height: 530px) {
      position: sticky;
      top: 0;
      max-height: 100vh;
    }
  }
  width: 100%;
  margin: 0 auto;
  max-width: rem(685);
  display: flex;
  flex-direction: column;
  > li {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    &:first-child {
      flex: auto;
    }
  }
}
.l-r-content-holder {
  margin: auto;
  width: 100%;
  padding: rem(40) 0 rem(20);
  @include desktop {
    padding: var(--gap) 0 calc(#{rem($baselineGap)} + var(--gap));
    max-width: per(360, 510);
    .registration & {
      padding-bottom: var(--gap);
    }
  }
}
