@import "src/assets/styles/variables.scss";
header {
  min-height: rem(60);
  display: flex;
  align-items: center;
  background: c(b);
  position: sticky;
  z-index: 800;
  top: 0;
  @include mobile {
    height: rem(80);
  }
  > ul {
    display: flex;
    align-items: center;
    > li {
      display: flex;
      align-items: center;
      &:first-child {
        flex: auto;
      }
      &:last-child {
        flex-shrink: 0;
      }
      > hr {
        margin: 0 rem(24);
        width: rem(1);
        display: block;
        height: rem(28);
        background: sc(b, 0.1);
        transition: 250ms opacity;
        &:nth-last-child(2):first-child {
          opacity: 0;
        }
        @include mobile {
          margin: 0 rem(20);
        }
      }
    }
  }
  .icon-logo-account, .letter-logo-account {
    cursor: pointer;
    font-size: rem(18);
    transition: 400ms color;
    @include hover {
      color: c(hero);
    }
    @include mobile {
      font-size: rem(16);
    }
  }
  .letter-logo-account {
    text-transform: uppercase;
    font-weight: 700;
  }
  .bet-construct-products-holder {
    opacity: 0;
    animation: bet-construct-products-holder 250ms forwards;
    @keyframes bet-construct-products-holder {
      to {
        opacity: 1;
      }
    }
  }
}
.profile-i-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  width: 100%;
  button {
    height: rem(24);
    padding: 0 rem(20);
    background: c(danger, 0.15);
    color: c(danger);
    font: 700 rem(12) $f;
    border-radius: rem(36);
    cursor: pointer;
    transition: 400ms color, 400ms background;
    @include hover {
      background: c(danger);
      color: white;
    }
    @include mobile {
      height: rem(36);
      padding: 0 rem(24);
      font-size: rem(14);
    }
  }
}
.user-profile-info {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 rem(8);
  > li {
    display: flex;
    flex-direction: column;
    &:first-child {
      flex-shrink: 0;
      margin: 0 rem(12) 0 0;
    }
    &:last-child {
      flex: auto;
      overflow: hidden;
    }
    > * {
      cursor: pointer;
    }
  }
  h5 {
    font-size: rem(20);
    margin: 0 0 rem(4);
    font-weight: 700;
    transition: 400ms opacity;
    opacity: 0.8;
    @include hover {
      opacity: 1;
    }
  }
  h6 {
    font-size: rem(12);
    font-weight: 400;
    transition: 400ms opacity;
    opacity: 0.5;
    @include hover {
      opacity: 0.8;
    }
  }
}
.header-actions {
  .drop-icon {
    &.icon-user {
      font-size: rem(18);
      border-radius: 100%;
      color: sc(b, 0.5);
      background: sc(b, 0.08) no-repeat 50% 50% / cover;
      transition: 400ms color, 400ms background;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: rgba(black, 0);
        transition: 400ms background;
      }
      &.big {
        width: rem(60);
        height: rem(60);
        font-size: rem(24);
      }
      &[style] {
        &:before {
          content: none;
        }
        @include hover {
          &:after {
            background: rgba(black, 0.1);
          }
        }
      }
      @include hover {
        color: sc(b, 0.8);
      }
    }
  }
  .drop-holder {
    position: relative;
    @include mobile {
      position: static !important;
    }
  }
  .drop-icon {
    width: rem(42);
    height: rem(42);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  svg.drop-icon {
    width: rem(24);
    height: rem(24);
    cursor: pointer;
    path {
      fill: sc(b, 0.5);
      transition: 400ms fill;
      @include hover {
        fill: sc(b, 0.8);
      }
    }
  }
  .drop-content-holder  {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 700;
    background: c(b) !important;
    border-radius: rem(4);
    white-space: nowrap;
    max-height: 0;
    overflow: hidden;
    transition: 900ms max-height linear, 400ms padding, 400ms opacity, 400ms visibility;
    opacity: 0;
    visibility: hidden;
    padding: 0 rem(20);
    box-shadow: 0 rem(3) rem(6) rgba(black, 0.1);
    margin: rem(4) 0 0;
    max-width: rem(340);
    @include mobile {
      width: 100%;
      margin: 0 !important;
      box-shadow: 0 rem(12) rem(12) rgba(black, 0.1) !important;
      border-top: rem(1) solid sc(b, 0.1);
      display: flex;
      justify-content: center;
      border-radius: 0 0 rem(20) rem(20) !important;
      max-width: inherit;
    }
    &.opened {
      visibility: visible;
      opacity: 1;
      max-height: rem(600);
      padding: rem(16) rem(20);
    }
  }
  .drop-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    &.hide {
      display: none;
    }
  }
  .products-popover {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: rem(300);
    > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font: 500 rem(12) / rem(14) $f;
      height: rem(100);
      width: rem(100);
      border-radius: rem(4);
      color: sc(b, 0.6) !important;
      img {
        display: block;
        width: rem(48);
        height: rem(48);
        margin: 0 0 rem(12);
        transition: 400ms transform;
      }
      span {
        display: block;
        transition: 400ms transform;
      }
      @include hover {
        img {
          transform: scale(1.1);
        }
        span {
          transform: translateY(rem(3));
        }
      }
    }
  }
}
