@import "mixinsAndFunctions";
$c: (
        hero: #4345E7,
        confirm: #4FA372,
        warning: #e6af57,
        danger: #E02F47,
        blue: #0072ee,
        link: #2A63B9,
        b: #ffffff,
        f: #171c26,
);
@each $name, $color in $c {
  $c: map-merge($c, (#{$name + '-hover'}: lighten($color, 8)));
  $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
  $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
  $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}

$f: 'Open Sans', 'Arial', 'Helvetica Neue', sans-serif;
$f-600: 600;
$baselineGap: 116;
