@import "src/assets/styles/variables.scss";
footer {
  width: 100%;
  border-top: rem(1) solid sc(b, 0.1);
  .root-container > & {
    background: c(b);
    border: 0;
  }
  @include mobile {
    padding: rem(20) 0;
  }
  ul {
    width: 100%;
    height: rem(50);
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      font-size: rem(11);
      height: auto;
      text-align: center;
    }
    > li {
      &:first-child {
        flex: auto;
        @include mobile {
          margin: 0 0 rem(20);
        }
      }
      &:last-child {
        flex-shrink: 0;
        > * {
          + * {
            margin: 0 0 0 rem(35);
            @include mobile {
              margin: 0 0 0 rem(18);
            }
          }
        }
      }
    }
  }
  a {
    color: c(link);
    @include hover {
      color: ch(link);
    }
  }
}
.company-name {
  text-transform: uppercase;
  font-weight: $f-600;
}
