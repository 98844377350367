@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?u0t4c6');
  src:  url('fonts/icomoon.eot?u0t4c6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?u0t4c6') format('woff2'),
    url('fonts/icomoon.ttf?u0t4c6') format('truetype'),
    url('fonts/icomoon.woff?u0t4c6') format('woff'),
    url('fonts/icomoon.svg?u0t4c6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-p-id-5:before {
  content: "\e91e";
}
.icon-logo-b-letter:before {
  content: "\e91f";
}
.icon-p-id-2:before {
  content: "\e91f";
}
.icon-arrow-down:before {
  content: "\e91d";
}
.icon-success-art:before {
  content: "\e91c";
}
.icon-spring-builder-shape:before {
  content: "\e901";
}
.icon-feed-construct-shape:before {
  content: "\e90b";
}
.icon-p-id-4:before {
  content: "\e90b";
}
.icon-spring-shape:before {
  content: "\e90c";
}
.icon-p-id-3:before {
  content: "\e90c";
}
.icon-spring-bme-shape:before {
  content: "\e90d";
}
.icon-p-id-1:before {
  content: "\e90d";
}
.icon-spring-builder-text:before {
  content: "\e90e";
}
.icon-spring-bme-text:before {
  content: "\e910";
}
.icon-spring-text:before {
  content: "\e916";
}
.icon-feed-construct-text:before {
  content: "\e91b";
}
.icon-question-mark:before {
  content: "\e91a";
}
.icon-logo-b:before {
  content: "\e919";
}
.icon-spinner:before {
  content: "\e914";
}
.icon-logo-account:before {
  content: "\e918";
}
.icon-caret-down:before {
  content: "\e917";
}
.icon-bell:before {
  content: "\e911";
}
.icon-check-circle:before {
  content: "\e912";
}
.icon-shield-check:before {
  content: "\e913";
}
.icon-user-alt:before {
  content: "\e915";
}
.icon-bet:before {
  content: "\e90f";
}
.icon-arrow-alt-to-bottom:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-camera:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-eye-off:before {
  content: "\e903";
}
.icon-eye:before {
  content: "\e904";
}
.icon-lock:before {
  content: "\e905";
}
.icon-long-arrow-right:before {
  content: "\e906";
}
.icon-trash:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
